import './App.css';
import ChatBox from './components/ChatBox';

function App() {

  return (
    <div className="App">
     <ChatBox />
    </div>
  );
}

export default App;
